.site-header {
  background-color: $brown-100;

  &-inner {
    @include make-container();
    
    max-width: map-get($container-max-widths, 'xxl');

    position: relative;
    display: flex;
    //flex-wrap: wrap;
    padding-top: ($spacer * 2);
    padding-bottom: ($spacer * 1.5);

    @include media-breakpoint-up(sm) {
      padding-top: ($spacer * 1);
    }
  }

  .brand {
    display: block;
    flex: 0 1 200px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .site-description {
    flex: 0 1 50%;
    margin-left: ($spacer * 2);
    align-self: center;
    //font-size: $font-size-sm;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      flex: 0 1 33.3333%;
    }
  }

  .navbar-toggler {
    align-self: flex-end;
    margin-left: auto;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

.site-menu {
  position: absolute;
  top: ($spacer / 2);
  right: ($grid-gutter-width / 2);
  //font-size: $font-size-sm;

  .nav {
    flex-direction: row;
    list-style: none;
  }

  li {
    line-height: 1;
    padding: 0 ($spacer / 2);

    & + li {
      border-left: 1px solid $body-color;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  a {
    color: $body-color;
  }
}

.header-menu {
  background-color: $brown-300;
  text-transform: uppercase;

  .navbar {
    @include make-container();
    
    max-width: map-get($container-max-widths, 'xxl');

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      padding-bottom: 0;
      //font-size: 80%;

      .navbar-nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex: 1 1 100%;
        //margin-left: -($spacer * 0.5);
        //margin-right: -($spacer * 0.5);

        .nav-item {
          flex-grow: 1;
        }

        .nav-link {
          padding: ($spacer * 0.75) ($navbar-nav-link-padding-x * 0.25);
          text-align: center;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      font-size: $font-size-base;

      .navbar-nav {
        .nav-link {
          padding: ($spacer * 0.75) $navbar-nav-link-padding-x;
        }
      }
    }
  }

  .nav-item {
    .nav-link {
      color: white;
    }

    &.active,
    & .nav-link:hover {
      background-color: $brown-400;
    }
  }

  .home-text {
    @include media-breakpoint-up(lg) {
      @include visually-hidden;
    }
  }

  // .home-icon {
  //   & + .home-text {
  //     margin-left: ($spacer * 0.25);
  //   }
  // }
}
