.btn-primary {
  .svg-inline--fa {
    color: $yellow;
    margin-left: ($spacer * 0.5);
    vertical-align: middle;
  }
}

.btn-info-contact {
  @extend .btn;
  @extend .btn-lg;

  @include button-variant($orange, $orange);

  position: relative;
  display: block;
  padding: $spacer $spacer $spacer ($spacer * 3);
  color: white;
  font-weight: 600;
  text-align: left;

  .svg-inline--fa {
    position: absolute;
    top: ($spacer * 1.25);
    left: $spacer;
    color: lighten($yellow, 20%);
  }
}
