/* Locaties */
.locations-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  .location-item {
    padding: 1rem;
    border: 1px solid $brown-200;
    text-align: center;
    box-sizing: border-box;

    h2 {
      color: inherit;
      font-size: $font-size-base;
    }

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    .location-image {
      width: 150px;
      height: 150px;
      margin: 0 auto 0.5rem;
    }
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
  }
}

/* Locatie */
.location-profile {
  margin-bottom: 16px;

  .location-info {
    margin-bottom: 16px;
  }

  dl {
    margin-top: 0;
  }

  dd {
    margin-left: 0;
  }
}
