.category-header {
  @include make-row();

  align-content: start;
}

.course-category{
  &.side-by-side {
    @include media-breakpoint-up(md) {
      .category-header,
      .category-courses {
        @include make-col(6);
      }
    }
  }
}

.category-description,
.category-extra {
  // margin-top: ($spacer * 2);
  // margin-bottom: ($spacer * 2);

  @include make-col-ready();
  @include make-col(12);
}

.category-description {
  .course-category:not(.side-by-side) & {
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
  }
}

.category-extra {
  .course-category:not(.side-by-side) & {
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(5);
      @include make-col-offset(1);
    }
  }
}

/* Courses */
.course-block {
  margin-bottom: $grid-gutter-width;
}

.course-grid {
  .course-block {
    @include make-col-ready();

    .course-category:not(.side-by-side) & {
      @include make-col-ready();
  
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
  
      @include media-breakpoint-up(md) {
        @include make-col(4);
      }
  
      @include media-breakpoint-up(xl) {
        @include make-col(3);
      }
    }

    .course-category.side-by-side & {
  
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
  
      @include media-breakpoint-up(md) {
        @include make-col(12);
      }
  
      @include media-breakpoint-up(xl) {
        @include make-col(6);
      }
    }

    .course-page & {
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }
  }

  .course-block-header {
    position: relative;
    margin: ($spacer * -1) ($spacer * -1) $spacer;
  }

  .course-block-title {
    margin: 0;
    font-size: $h4-font-size;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    //padding: ($spacer / 2) $spacer;
    color: $white;
    background-color: rgba(100, 100, 100, 0.52);
  }

  .course-block-title a {
    padding: ($spacer / 2) $spacer;
    display: block;
    color: inherit;
    margin-top: 0;
    text-decoration: none;
  }

  .course-block-content {
    position: relative;
    height: 100%;
    padding: 16px 16px 52px 16px;
    border: 1px solid $brown-100;
  }

  .course-block-date {
    font-weight: 600;
  }

  .course-block .btn {
    position: absolute;
    bottom: 16px;
    left: 16px;
  }
}

.course-list {
  .course-block {
    & + .course-block {
      margin-top: ($spacer * 2);
    }
  }
}

/**
 * PRT
 **/
.prt-page {
  @include make-row();

  margin: ($grid-gutter-width * -1) ($grid-gutter-width * -1);

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }
}

.prt-content {
  @extend .content;
}

.prt-sidebar {
  @extend .content-sidebar;
}
