.main {
  > .container-xxl {
    > .row {
      @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
      }
    }
  }

  img {
    @include img-fluid;
  }
}

.content {
  @include make-col-ready();
  @include make-col(12);

  padding: $grid-gutter-width;

  @include media-breakpoint-up(md) {
    flex: 1 1 auto;
    width: auto;
    max-width: 100%;
  }
}

.content-sidebar {
  padding: $grid-gutter-width !important;
  background-color: $brown-100;

  @include make-col-ready();
  @include make-col(12);

  @include media-breakpoint-up(md) {
    @include make-col(5);
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

body.com_rsform {
  .content {
    background-color: $brown-100;
  }

  .content-sidebar {
    background-color: white;

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
  }
}

fieldset {
  legend {
    font-size: $font-size-lg;
  }
}

strong {
  font-weight: 600;
}