.course-image {
  margin-bottom: ($spacer);
}

.course-sessions {
  .accordion-body {
    p:last-child {
      margin-bottom: 0;
    }
  }
}

// .course-info
.course-info {
  &-english,
  &-accordion,
  &-text,
  &-calendar,
  .moduletable {
    margin-bottom: ($spacer * 2);

    &:last-child,
    > *:last-child {
      margin-bottom: 0;
    }
  }

  &-calendar {
    #calendarItemTab {      
      .btn {
        border: 1px solid white;
        background-color: #f59944;
        color: white;

        &:hover {
          background-color: darken(#f59944, 10%);
        }

        &.active {
          background-color: $primary;

          &:hover {
            background-color: darken($primary, 10%);
          }  
        }
      }
    }

    .calendar-item {
      // &,
      &-info,
      &-select,
      &-table,
      &-trainers,
      &-sessions {
        margin-bottom: ($spacer * 2);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .calendar-item-sessions {
    background-color: #f59944;
    padding: 0 ($spacer);

    h3 {
      margin: 0 ($spacer * -1);
      padding: ($spacer * 0.5) ($spacer);
      width: calc(100% + #{($spacer * 2)});
      background-color: $primary;
      color: $white;
      text-align: center;
    }
    
  }
  
  .calendar-session {
    padding: ($spacer * 1) 0;
    color: white;
    
    h4, a {
      color: inherit;
    }
    
    h4 {
      font-weight: 700;
      margin-bottom: 0;
    }

    time {
      display: block;
      margin-bottom: ($spacer * 0.5);
    }

    & + .calendar-session {
      border-top: 1px solid white;
    }

  }
}

.calendar-item-trainers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $spacer;
  margin-bottom: ($spacer * 2);

  .trainer {
    position: relative;
    display: block;
    // margin-bottom: 10px;
    // float: left;
    // width: 31.666667%;
    
    // & + .trainer {
      //   margin-left: 2.5%;
      // }
      
      // &:nth-child(3n+1) {
        //   clear: left;
        //   margin-left: 0;
        // }
        
    .trainer-name {
      position: absolute;
      bottom: 8px;
      display: inline-block;
      padding: 2px 0;
      width: 100%;
      text-align: center;
      background: rgba(255, 255, 255, 0.7);
      color: $primary;
      text-decoration: none;
      transition: background-color 0.2s linear, color 0.1s linear;
    }

    &:hover > .trainer-name {
      background: $primary;
      color: $white;
    }
  }

  .trainer-image img {
    width: 100%;
  }

  
}

/*
@media only screen and (max-width: 479px), only screen and (min-width: 768px) and (max-width: 995px) {
  .course-trainers {
    .trainer {
      width: 48%;

      &:nth-child(3n+1) {
        clear: none;
      }

      & + .trainer {
        margin-left: 4%;
      }

      .trainer:nth-child(2n+1) {
        clear: left;
        margin-left: 0;
      }
    }
  }
}
*/