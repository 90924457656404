.tbl-assistantlist,
.tbl-jaargang,
.tbl-agenda {
  border-collapse: collapse;
  width: 100%;

  th {
    font-weight: 600;
  }
}

.tbl-jaargang {
  margin-bottom: 16px;
}

.tbl-agenda th,
.tbl-jaargang td {
  text-align: center;
}

.tbl-assistantlist th,
.tbl-assistantlist td,
.tbl-jaargang td,
.tbl-agenda th,
.tbl-agenda td {
  border: 2px solid $white;
  padding: 4px;
}

.tbl-assistantlist th,
.tbl-jaargang td.selected,
.tbl-agenda th {
  background-color: $primary;
  color: $white;
}

.tbl-jaargang td {
  background-color: #f59944;
  color: $white;
}

.tbl-assistantlist td,
.tbl-agenda td {
  color: #575757;
  background: $brown-100;
  padding: ($spacer / 4) ($spacer / 2);
  vertical-align: top;
}

.tbl-jaargang td:not(.selected) {
  cursor: pointer;
}

.tbl-assistantlist a,
.tbl-agenda a {
  color: #575757;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $primary;
  }
}

.tbl-agenda .datum {
  width: 100px;
}

.tbl-agenda .meditation {
  font-size: 1.1em;
}

.tbl-agenda .session-name {
  font-weight: 500;
}

.tbl-agenda .course-name {
  font-size: 0.875rem;
}

.tbl-agenda .trainers {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
}
.tbl-agenda .trainer {
  display: block;
  padding: 0 0.5rem;
  border-right: 1px solid $white;
}

.tbl-agenda .trainer:first-of-type {
  padding-left: 0;
}

.tbl-agenda .trainer:last-of-type {
  padding-right: 0;
  border-right: none;
}

.content .tbl-agenda {
  margin-bottom: 16px;
}

.course-info .tbl-agenda td {
  background-color: #f59944;
  color: $white;
}

.course-info .tbl-agenda a {
  color: $white;
  text-decoration: none;
}

// .course-info .calendar-item {
//   display: none;
// }

// .course-info .calendar-item.selected {
//   display: block;
// }
