/* stylelint-disable no-descending-specificity */

.site-footer {
  padding: ($spacer * 2) 0;
  background-color: $brown-200;
  font-size: $font-size-sm;

  .container-fluid {
    max-width: map-get($container-max-widths, 'xxl');
  }

  h3 {
    margin-top: $spacer;
    margin-bottom: ($spacer * 0.125);
    font-size: $font-size-base;
    font-weight: 600;
    color: $body-color;
  }

  .nav {
    margin-top: 0;
    padding-left: 0;
    list-style: none;
  }

  li {
    & + li {
      margin-top: ($spacer * 0.125);
    }
  }

  .custom,
  .nav {
    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        color: $primary;
      }
    }

    .current a,
    .active a {
      color: $primary;
    }
  }
}

.footer-col {
  @include make-col-ready();

  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(2, 10);
  }
}

.copyright {
  text-align: center;
  color: $brown-400;
  margin: ($spacer * 1.5) auto 0;

  a {
    color: inherit;
  }
}
