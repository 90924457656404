/* Trainers */
.trainers-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  h2 {
    color: inherit;
    font-size: $font-size-base;
  }

  a {
    display: block;
    height: 100%;
    padding: $spacer;
    border: 1px solid #e6ddd1;
    text-align: center;
    text-decoration: none;
    color: inherit;
    //font-size: $font-size-sm;
  }

  a:hover {
    text-decoration: underline;
  }

  .trainer-image {
    width: 150px;
    height: 150px;
    margin: 0 auto 0.5rem;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Trainer */
.trainer-profile {
  margin-bottom: 16px;

  .trainer-info {
    margin-bottom: 16px;
  }

  .trainer-image {
    float: left;
    margin-right: 16px;
  }

  .trainer-contact-info {
    margin-top: 0;
  }

  .trainer-contact-info dd {
    margin-left: 0;
  }

  .trainer-portfolio {
    clear: left;
  }
}
