/* stylelint-disable no-descending-specificity */

.home-banner,
.home-row-2,
.home-row-3 {
  .container-fluid {
    max-width: map-get($container-max-widths, 'xxl');
  }
}

.home-banner {
  padding: ($spacer) 0;

  .row {
    min-height: 320px;
  }

  .home-watzoekje,
  .home-quote {
    @include make-col-ready();
    @include make-col(12);

    // @include media-breakpoint-up(sm) {
    //   @include make-col(6);
    // }

    margin-top: ($spacer / 2);
    margin-bottom: ($spacer / 2);
  }

  .home-watzoekje {
    @include media-breakpoint-up(md) {
      @include make-col(5);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
  }

  .home-quote {
    @include media-breakpoint-up(md) {
      @include make-col(7);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
  }

  .mod-custom {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;

    p {
      padding: ($spacer * 4) ($spacer) ($spacer);
      max-width: 500px;
      margin: 0;
      color: white;
      font-size: $font-size-lg;
      font-weight: 300;
      text-shadow: 0 0 5px #000;

      @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: ($spacer);
      }
    }
  }
}

.home-watzoekje .nav {
  list-style: none;
  padding-left: 0;
  height: 100%;
  align-content: stretch;

  li {
    display: flex;
    background-color: $primary;
    width: 100%;

    & + li {
      margin-top: ($grid-gutter-width * 0.5);
    }

    .svg-inline--fa {
      position: absolute;
      top: ($spacer * 1.25);
      left: $spacer;
      color: lighten($yellow, 20%);
    }
  }

  a {
    display: flex;
    position: relative;
    padding: $spacer $spacer $spacer ($spacer * 3);
    color: white;
    font-size: 1.15em;
    font-weight: 600;
    text-decoration: none;
    align-self: center;

    &:hover {
      text-decoration: underline;
    }
  }
}

.home-row-2 {
  padding: ($spacer) 0;
  background-color: $brown-100;

  .home-uitgelicht {
    @include make-col-ready();
    @include make-col(12);

    // @include media-breakpoint-up(md) {
    //   @include make-col(8);
    // }

    .nav {
      @include make-row();

      list-style: none;
      flex-direction: row;
      padding-left: 0;
    }

    li {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      position: relative;
      // margin-top: ($spacer / 2);
      // margin-bottom: ($spacer / 2);
      margin-top: ($grid-gutter-width * 0.5);
      margin-bottom: ($grid-gutter-width * 0.5);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      span {
        display: block;
        position: absolute;
        left: ($grid-gutter-width / 2);
        bottom: 0;
        padding: ($spacer / 4) ($spacer / 2);
        width: calc(100% - #{$grid-gutter-width});
        font-size: 1.15em;
        text-align: center;
        background: rgba(100, 100, 100, 0.52);
        text-decoration: none;
        color: white;
      }
    }

    & + div {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(md) {
        @include make-col(4);
      }
    }
  }
}

.home-row-3 {
  padding: ($spacer) 0;
}

.home-row-3-col {
  @include make-col-ready();
  @include make-col(12);

  margin-top: ($spacer);

  @include media-breakpoint-up(md) {
    @include make-col(4);

    margin-top: 0;
  }

  > div + div {
    margin-top: ($spacer * 1.5);
  }
}

.home-actueel {
  h2 {
    background-color: $brown-200;
    padding: ($spacer / 4) ($spacer / 2);
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  li {
    display: flex;
    padding-top: ($spacer / 2);
    padding-bottom: ($spacer / 2);
    // font-size: $font-size-sm;

    & + li {
      border-top: 1px solid $gray-500;
    }
  }

  a {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    color: inherit;
    text-decoration: none;

    &:hover {
      background-color: $brown-100;
    }
  }

  img {
    flex: 0 0 70px;
    width: 70px;
    object-fit: cover;
    object-position: center;
    margin-right: $spacer;
  }

  span {
    flex: 1 1 100%;
    //vertical-align: top;
  }

  .svg-inline--fa {
    color: $yellow;
    //vertical-align: middle;
    margin-left: ($spacer * 0.25);
  }
}
