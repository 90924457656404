/* PRT Therapeuten */
.prt-plaats {
  h3 {
    margin: 0;
  }

  & + .prt-plaats {
    margin-top: 0.5rem;
  }
}

.prt-therapeuten-toggle {
  padding: 0.5rem;
  // margin: 1rem 0;
  display: block;
  width: 100%;
  border: 0;
  background-color: transparent;
  color: $gray-700;
  text-align: left;
  font-size: $h4-font-size;
  font-weight: 700;

  &[aria-expanded="true"],
  &:hover {
    background-color: $brown-100;
  }

  &:hover {
    color: $primary;
    cursor: pointer;
  }

  .svg-inline--fa {
    transition: transform 0.35s ease;
    margin-right: ($spacer / 2);
  }

  &[aria-expanded="true"] {
    .svg-inline--fa {
      transform: rotate(180deg);
    }
  }
}

.prt-therapeuten {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  overflow: hidden;
  padding: 0.5rem 0 0;

  .therapeut {
    padding: 1rem;
    background-color: $brown-100;
  }
  
  ul {
    margin-bottom: 0;
    margin-left: 1.5em;
    //font-size: $font-size-sm;
  }
  
  li[class^="therapeut-"] {
    white-space: nowrap;
  
    a {
      display: inline-block;
      line-height: 1;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }  
}
 
@include media-breakpoint-up(lg) {
  .prt-therapeuten {
    grid-template-columns: 1fr 1fr;
  }
}
