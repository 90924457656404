.nav-list {
  flex-direction: column;
  list-style-type: square;
  padding-left: 1em;

  .nav-item {
    &::marker {
      color: $brown-300;
    }

    .nav-link {
      color: $body-color;
      padding: 0;
    }

    &.active .nav-link,
    & .nav-link:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.nav {
  &.menu {
    @extend .nav-list;
  }
}
