.com_users {
  &.view-login {
    .login-description {
      margin-bottom: ($spacer);
    }

    form {
      margin-bottom: ($spacer);
    }
  }
}
